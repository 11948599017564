import React, { useState } from 'react';
import { useStore } from 'effector-react';
import { CardTableRow, CardTableHeader, TaskEditForm } from '../components';
import {
    getCards,
    addTask,
    getTasksForSprintId,
    getTasksForSprintMonthAndYear,
    scheduleSprint,
    previousSprints,
    previousMonthSprints,
    summarizeResults,
    getConfiguration,
    tasksGlobalSearch
} from '../components/api';
import {
    $cards,
    $users,
    setCards,
    setFilteredSprints,
    $filteredSprints,
    $authUserData,
    $statuses,
    $repositories
} from '../store/cards';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Button,
    IconButton,
    Select,
    MenuItem
} from '@mui/material';
import {
    Settings,
    Analytics,
    Close,
    Refresh,
    ArrowLeft,
    ArrowRight,
} from '@mui/icons-material';
import '../styles/main.scss';

export function Main() {
    const cards = useStore($cards);
    const users = useStore($users);
    const authUserData = useStore($authUserData);
    const filteredSprints = useStore($filteredSprints);
    const statuses = useStore($statuses);
    const repositories = useStore($repositories);
    const [searchValue, setSearchValue] = useState('');
    const [responsibleSelectValue, setResponsibleSelectValue] = useState(0);
    const [accompliceSelectValue, setAccompliceSelectValue] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const [showSheduleSprint, setShowSheduleSprint] = useState(false);
    const [selectedSprint, setSelectedSprint] = useState(null);
    const [statusValue, setStatusValue] = useState(0);
    const [sprintPage, setSprintPage] = useState(0);
    const [monthSprintPage, setMonthSprintPage] = useState(0);
    const [sprintPagesCount, setSprintPagesCount] = useState(0);
    const [monthSprintPagesCount, setMonthSprintPagesCount] = useState(0);
    const [lastSelectedSprintId, setLastSelectedSprintId] = useState(null);
    const [showEditForm, setShowEditForm] = useState(false);
    const [editedTask, setEditedTask] = useState(null);
    const [repositorySelectValue, setRepositorySelectValue] = useState(0);
    const [showSummarizeButton, setShowSummarizeBotton] = useState(false);
    const [spSelectValue, setSpSelectValue] = useState(null);
    const [prioritySelectValue, setPrioritySelectValue] = useState(null);
    const [useIssue, setUseIssue] = useState(true);
    const [isGlobalSearch, setIsGlobalSearch] = useState(false);

    React.useEffect(() => {
        if (filteredSprints.last_sprints && filteredSprints.month_sprints) {
            const lastSprint = filteredSprints.last_sprints.findLast(
                sprint => new Date(sprint.date_end) >= new Date() && new Date(sprint.date_start) <= new Date()
            );
            if (lastSprint) {
                const sprintId = `sprint-${lastSprint.month * lastSprint.year + lastSprint.month_number}`;
                setLastSelectedSprintId(sprintId);
                document.getElementById(sprintId).classList.add("selected");
            }
            setSelectedSprint(lastSprint);
            setShowSheduleSprint(true);
            getConfiguration().then(data => setUseIssue(data.useIssue));
            setIsLoaded(true);
        }
    }, [filteredSprints]);

    const searchChanged = (event) => {
        const value = event.target.value;

        if (value === '' && isGlobalSearch) {
            setIsGlobalSearch(false);
            getTasksForSprintId(selectedSprint.id).then(data => setCards(data));
            changeSelectedSprintElement(lastSelectedSprintId);
        }
        else if (isGlobalSearch) {
            tasksGlobalSearch(value).then(data => setCards(data));
        };
        setSearchValue(event.target.value);
    }

    const onPasteSearch = (event) => {
        event.preventDefault();
        setSearchValue(event.clipboardData.getData('text').trimEnd());
        tasksGlobalSearch(searchValue).then(data => setCards(data));
    }

    const clearSearch = () => {
        setSearchValue('');
        setIsGlobalSearch(false);
        getTasksForSprintId(selectedSprint.id).then(data => setCards(data));
        changeSelectedSprintElement(lastSelectedSprintId);

    }

    const selectResponsibleAndAccomplice = (event, isResponsible) => {
        const userId = authUserData.bitrix_user_id;
        if (isResponsible) {
            const responsibleValue = responsibleSelectValue !== userId ? userId : 0;
            setResponsibleSelectValue(responsibleValue);
            if (accompliceSelectValue === userId) setAccompliceSelectValue(0);
        }
        else {
            const accompliceValue = accompliceSelectValue !== userId ? userId : 0;
            setAccompliceSelectValue(accompliceValue);
            if (responsibleSelectValue === userId) setResponsibleSelectValue(0);
        }
    }

    const changeSelectResponsible = (event) => {
        setResponsibleSelectValue(event.target.value);
    }

    const changeSelectAccomplice = (event) => {
        setAccompliceSelectValue(event.target.value);
    }

    const changeSelectStatus = (event) => {
        setStatusValue(event.target.value);
    }

    const addTaskButton = () => {
        if (searchValue) {
            addTask({ bitrix_task_id: searchValue });
            setSearchValue("");
            setTimeout(() => {
                getCards().then(data => setCards(data));
            }, 1000);
        }

    }

    const formattedData = (month, year) => {
        var options = {
            year: 'numeric',
            month: 'long',
            timezone: 'UTC'
        };
        const date = new Date(`${year}-${month}-01`);
        return `${date.toLocaleString("ru", options)}`
    };

    const getMonth = (month, year) => {
        var options = {
            month: 'long',
            timezone: 'UTC'
        };
        const date = new Date(`${year}-${month}-01`);
        return `${date.toLocaleString("ru", options)}`
    };

    const filteredCards = cards.filter(card => {
        const hasResponsible = Number(responsibleSelectValue) === 0 ? true : Number(card.responsible_id) === Number(responsibleSelectValue);
        const hasAccomplice = Number(accompliceSelectValue) === 0 ? true : Number(card.accomplice_id) === Number(accompliceSelectValue);
        const hasStatus = Number(statusValue) === 0 ? true : Number(card.status.id) === Number(statusValue);
        const spFilter = spSelectValue === null ? true : spSelectValue === card.sp_count;
        const priorityFilter = prioritySelectValue === null ? true : prioritySelectValue === card.priority;
        const hasRepository = Number(repositorySelectValue) === 0
            ? true
            : Number(card.repository
                ? card.repository.id
                : 0) === Number(repositorySelectValue);
        return (card.issue_id.toString().includes(searchValue)
            || card.task_id.toString().includes(searchValue)
            || card.pr_id.toString().includes(searchValue)
            || card.title.toString().toLowerCase().includes(searchValue.toLowerCase()))
            && hasResponsible
            && hasAccomplice
            && hasStatus
            && hasRepository
            && spFilter
            && priorityFilter;
    });

    const getSPCount = () => {
        let allSP = 0;
        let doneSP = 0;
        filteredCards.map(card => allSP += (card.sp_count === 0 ? 0.5 : card.sp_count));
        filteredCards.filter(card => card.status.id === 7).map(card => doneSP += (card.sp_count === 0 ? 0.5 : card.sp_count));
        return `SP ${doneSP}/${allSP}`;
    }

    const getTasksCount = () => {
        let allTasks = filteredCards.length ?? 0;
        let doneTasks = 0;
        filteredCards.filter(card => card.status.id === 7).map(() => doneTasks++);
        return `Задачи ${doneTasks}/${allTasks}`;
    }

    const getSprintForId = (event, sprint) => {
        changeSelectedSprintElement(event.target.id);
        setSelectedSprint(sprint);
        getTasksForSprintId(sprint.id).then(data => setCards(data));
        setShowSheduleSprint(new Date(sprint.date_end) > new Date());
        setShowSummarizeBotton(false);
        setIsGlobalSearch(false);
    }

    const getSprintForMonth = (event, sprint) => {
        changeSelectedSprintElement(event.target.id);
        setSelectedSprint(sprint);
        getTasksForSprintMonthAndYear(sprint.year, sprint.month).then(data => setCards(data));
        setShowSheduleSprint(false);
        setShowSummarizeBotton(true);
        setIsGlobalSearch(false);
    }

    const changeSelectedSprintElement = (targetId) => {
        const lastSprintElement = document.getElementById(lastSelectedSprintId);
        if (lastSprintElement)
            lastSprintElement.classList.remove("selected");
        document.getElementById(targetId).classList.add("selected");
        setLastSelectedSprintId(targetId);
    }

    const sheduleSprintButtonClick = () => {
        const sprintNumber = selectedSprint ? selectedSprint.number : 0;
        scheduleSprint(sprintNumber).then(() => {
            getTasksForSprintId(selectedSprint.id).then(data => setCards(data));
        });
    }

    const refreshButtonClick = () => {
        selectedSprint.month_number
            ? getTasksForSprintId(selectedSprint.id).then(data => setCards(data))
            : getTasksForSprintMonthAndYear(selectedSprint.year, selectedSprint.month).then(data => setCards(data));
    }

    const getPreviousSprint = () => {

        let page = sprintPage;
        page++;
        previousSprints(page).then(data => {
            if (data.sprints.length) setSprintPage(page);
            let sprints = filteredSprints;
            sprints.last_sprints = data.sprints;
            setFilteredSprints(sprints);
            setSprintPagesCount(data.pagesCount);
            findSelectedSprintElement(data);
        })

    }

    const getNextSprint = () => {
        let page = sprintPage;
        if (page > 0) {
            page--;
            previousSprints(page).then(data => {
                let sprints = filteredSprints;
                sprints.last_sprints = data.sprints;
                setFilteredSprints(sprints);
                setSprintPage(page);
                findSelectedSprintElement(data);
            })
        }

    }

    const getPreviousMonthSprint = () => {

        let page = monthSprintPage;
        page++;
        previousMonthSprints(page).then(data => {
            if (data.sprints.length) setMonthSprintPage(page);
            let sprints = filteredSprints;
            sprints.month_sprints = data.sprints;
            setFilteredSprints(sprints);
            setMonthSprintPagesCount(data.pagesCount);
            findSelectedSprintElement(data);
        })

    }

    const getNextMonthSprint = () => {
        let page = monthSprintPage;
        if (page > 0) {
            page--;
            previousMonthSprints(page).then(data => {
                let sprints = filteredSprints;
                sprints.month_sprints = data.sprints;
                setFilteredSprints(sprints);
                setMonthSprintPage(page);
                findSelectedSprintElement(data);
            })
        }

    }

    const findSelectedSprintElement = (sprints) => {
        const sprintElementData = sprints.sprints.find(s =>
            lastSelectedSprintId === (s.month_number ? `sprint-${s.year * s.month + s.month_number}` : `monthSprint-${s.year * s.month}`));
        if (sprintElementData) {
            const sprintId = sprintElementData.month_number
                ? `sprint-${sprintElementData.year * sprintElementData.month + sprintElementData.month_number}`
                : `monthSprint-${sprintElementData.year * sprintElementData.month}`;
            setTimeout(() => {
                document.getElementById(sprintId).classList.add("selected");
            }, 500);

        }
    }

    const editTask = (task) => {
        if (task) {
            setShowEditForm(true);
            setEditedTask(task);
        }
    }

    const getCountWeeksInSprint = (dateStart, dateEnd) => {
        const date1 = new Date(dateStart);
        const date2 = new Date(dateEnd);

        // One day in milliseconds 
        const oneDay = 1000 * 60 * 60 * 24;

        // Calculating the time difference between two dates 
        const diffInTime = date2.getTime() - date1.getTime();

        // Calculating the no. of days between two dates 
        const diffInDays = Math.round(diffInTime / oneDay);
        return Math.ceil(diffInDays / 7);
    }

    const summarizeButtonClick = () => {
        summarizeResults(selectedSprint.year, selectedSprint.month);
    }

    const findFromAllCards = (event) => {
        const lastSprintElement = document.getElementById(lastSelectedSprintId);
        if (lastSprintElement)
            lastSprintElement.classList.remove("selected");
        document.getElementById("all").classList.add("selected");
        setIsGlobalSearch(true);
        tasksGlobalSearch(searchValue).then(data => setCards(data));
    }

    return (
        <div className="main">
            <div className='functional-items'>
                <IconButton onClick={refreshButtonClick}>
                    <Refresh />
                </IconButton>
                <a href='/reportPage'>
                    <IconButton>
                        <Analytics />
                    </IconButton>
                </a>
                {
                    authUserData && authUserData.is_head_of_department
                        ? <a href='/admin'>
                            <IconButton>
                                <Settings />
                            </IconButton>
                        </a>
                        : null
                }
                {authUserData && authUserData.is_head_of_department ? <Button variant="contained" className='functional-items__button' onClick={addTaskButton}>Добавить задачу</Button> : null}

                <TextField label="Найти/добавить задачу" variant="standard" onChange={searchChanged} value={searchValue} onPaste={onPasteSearch} />
                <IconButton style={{ marginTop: 15 }} onClick={clearSearch}>
                    <Close />
                </IconButton>

            </div>
            <div className='general-report'>
                <h3>{getTasksCount()}</h3>
                <h3>{getSPCount()}</h3>
            </div>
            <TableContainer component={Paper} sx={{ maxHeight: "calc(100vh - 170px)" }}>
                <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="sticky table">
                    <TableHead id="table-head">
                        <TableRow>
                            <TableCell
                                width={authUserData && authUserData.is_head_of_department ? 20 : 10}
                                className="table-column"
                                align="center"
                            >
                            </TableCell>
                            <TableCell width={50} align="center" className="table-column">
                                <div className="table-column__item">
                                    <Select
                                        className='table-combobox'
                                        value={repositorySelectValue}
                                        onChange={(event) => setRepositorySelectValue(event.target.value)}
                                    >
                                        <MenuItem value={0} key={0}>Репозиторий</MenuItem>
                                        {repositories.map((repository) => {
                                            return cards.filter(card => card.repository && card.repository.id === repository.id).length
                                                ? (<MenuItem value={repository.id} key={repository.id}>{repository.name}</MenuItem>)
                                                : null
                                        })}
                                    </Select>
                                    {
                                        repositorySelectValue
                                            ? <IconButton onClick={() => setRepositorySelectValue(0)}>
                                                <Close style={{ fontSize: 16 }} />
                                            </IconButton>
                                            : null
                                    }

                                </div>
                            </TableCell>
                            <TableCell width={40} align="center" className="table-column"><b>№</b></TableCell>
                            <TableCell width={'29%'} align="left" className="table-column" style={{ minWidth: '30vw' }}><b>Название</b></TableCell>
                            {useIssue
                                ? <TableCell width={10} align="center" className="table-column"><b>Issue</b></TableCell>
                                : null}
                            <TableCell width={10} align="center" className="table-column"><b>PR</b></TableCell>
                            <TableCell width={150} className="table-column">
                                <div className='table-header__column'>
                                    <Select className='table-combobox__header' value={responsibleSelectValue} onChange={changeSelectResponsible}>
                                        <MenuItem value={0} key={0}>Исполнитель</MenuItem>
                                        {users.filter(user => (
                                            user.active && cards.filter(card => card.responsible_id === Number(user.bitrix_user_id)).length) && user.id !== -1
                                        ).map((user) => {
                                            return (<MenuItem value={user.bitrix_user_id} key={user.id}>{`${user.surname} ${user.name}`}</MenuItem>)
                                        })}
                                    </Select>
                                    <Button
                                        className='table-header__button'
                                        variant={authUserData && responsibleSelectValue === authUserData.bitrix_user_id ? "contained" : "outlined"}
                                        onClick={(event) => selectResponsibleAndAccomplice(event, true)}
                                    >
                                        Я
                                    </Button>
                                    {
                                        responsibleSelectValue
                                            ? <IconButton onClick={() => setResponsibleSelectValue(0)}>
                                                <Close style={{ fontSize: 16 }} />
                                            </IconButton>
                                            : null
                                    }
                                </div>
                            </TableCell>
                            <TableCell width={50} className="table-column">
                                <div className='table-header__column'>
                                    <Select className='table-combobox__header' value={accompliceSelectValue} onChange={changeSelectAccomplice}>
                                        <MenuItem value={0} key={0}>Ревьювер</MenuItem>
                                        {users.filter(user =>
                                            user.active && cards.filter(card => card.accomplice_id === Number(user.bitrix_user_id)).length
                                        ).map((user) => {
                                            return (
                                                <MenuItem value={user.bitrix_user_id} key={user.id}
                                                >
                                                    {`${user.surname} ${user.name}`} {user.id > 0 ? `(${user.review_tasks_count})` : null}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                    <Button
                                        className='table-header__button'
                                        variant={authUserData && accompliceSelectValue === authUserData.bitrix_user_id ? "contained" : "outlined"}
                                        onClick={(event) => selectResponsibleAndAccomplice(event, false)}
                                    >
                                        Я
                                    </Button>
                                    {
                                        accompliceSelectValue
                                            ? <IconButton onClick={() => setAccompliceSelectValue(0)}>
                                                <Close style={{ fontSize: 16 }} />
                                            </IconButton>
                                            : null
                                    }
                                </div>
                            </TableCell>
                            <TableCell width={10} align="center" className="table-column">
                                <div className='table-header__column'>
                                    <b>SP</b>
                                    {
                                        spSelectValue !== null
                                            ? <IconButton onClick={() => setSpSelectValue(null)}>
                                                <Close style={{ fontSize: 16 }} />
                                            </IconButton>
                                            : null
                                    }
                                </div>
                            </TableCell>
                            <TableCell width={10} align="center" className="table-column">
                                <div className='table-header__column'>
                                    <b>Приоритет</b>
                                    {
                                        prioritySelectValue !== null
                                            ? <IconButton onClick={() => setPrioritySelectValue(null)}>
                                                <Close style={{ fontSize: 16 }} />
                                            </IconButton>
                                            : null
                                    }
                                </div>
                            </TableCell>
                            <TableCell width={210} align="center" className="table-column">
                                <div className='table-column__item'>
                                    <Select className='table-combobox' value={statusValue} onChange={changeSelectStatus}>
                                        <MenuItem value={0} key={0}>Статус</MenuItem>
                                        {statuses.map((status) => {
                                            return cards.filter(card => card.status && card.status.id === status.id).length
                                                ? (<MenuItem value={status.id} key={status.id}>{status.name}</MenuItem>)
                                                : null
                                        })}
                                    </Select>
                                    {
                                        statusValue
                                            ? <IconButton onClick={() => setStatusValue(0)}>
                                                <Close style={{ fontSize: 16 }} />
                                            </IconButton>
                                            : null
                                    }
                                </div>
                            </TableCell>
                            <TableCell width={90} align="center" className="table-column" title='Дней в текущем статусе'><b>Дни / PR слит</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <CardTableHeader title="В работе" cards={filteredCards.filter(card => card.status.id === 2)} />
                        {filteredCards.filter(card => card.status.id === 2 && card.accomplice_id === 0).map((row) => (
                            <CardTableRow
                                card={row}
                                key={row.id}
                                editTask={editTask}
                                setRepositorySelectValue={setRepositorySelectValue}
                                setResponsibleSelectValue={setResponsibleSelectValue}
                                setAccompliceSelectValue={setAccompliceSelectValue}
                                setStatusValue={setStatusValue}
                                setSpSelectValue={setSpSelectValue}
                                setPrioritySelectValue={setPrioritySelectValue}
                                useIssue={useIssue}
                            ></CardTableRow>
                        ))}
                        {filteredCards.filter(card => card.status.id === 2 && card.accomplice_id !== 0).map((row) => (
                            <CardTableRow
                                card={row}
                                key={row.id}
                                editTask={editTask}
                                setRepositorySelectValue={setRepositorySelectValue}
                                setResponsibleSelectValue={setResponsibleSelectValue}
                                setAccompliceSelectValue={setAccompliceSelectValue}
                                setStatusValue={setStatusValue}
                                setSpSelectValue={setSpSelectValue}
                                setPrioritySelectValue={setPrioritySelectValue}
                                useIssue={useIssue}
                            ></CardTableRow>
                        ))}
                        <CardTableHeader title="На ревью" cards={filteredCards.filter(card => card.status.id === 3)} />
                        {filteredCards.filter(card => card.status.id === 3).map((row) => (
                            <CardTableRow
                                card={row}
                                key={row.id}
                                editTask={editTask}
                                setRepositorySelectValue={setRepositorySelectValue}
                                setResponsibleSelectValue={setResponsibleSelectValue}
                                setAccompliceSelectValue={setAccompliceSelectValue}
                                setStatusValue={setStatusValue}
                                setSpSelectValue={setSpSelectValue}
                                setPrioritySelectValue={setPrioritySelectValue}
                                useIssue={useIssue}
                            ></CardTableRow>
                        ))}
                        <CardTableHeader title="Уточнения по ревью" cards={filteredCards.filter(card => card.status.id === 8)} />
                        {filteredCards.filter(card => card.status.id === 8).map((row) => (
                            <CardTableRow
                                card={row}
                                key={row.id}
                                editTask={editTask}
                                setRepositorySelectValue={setRepositorySelectValue}
                                setResponsibleSelectValue={setResponsibleSelectValue}
                                setAccompliceSelectValue={setAccompliceSelectValue}
                                setStatusValue={setStatusValue}
                                setSpSelectValue={setSpSelectValue}
                                setPrioritySelectValue={setPrioritySelectValue}
                                useIssue={useIssue}
                            ></CardTableRow>
                        ))}
                        <CardTableHeader title="На исправлении" cards={filteredCards.filter(card => card.status.id === 4)} />
                        {filteredCards.filter(card => card.status.id === 4).map((row) => (
                            <CardTableRow
                                card={row}
                                key={row.id}
                                editTask={editTask}
                                setRepositorySelectValue={setRepositorySelectValue}
                                setResponsibleSelectValue={setResponsibleSelectValue}
                                setAccompliceSelectValue={setAccompliceSelectValue}
                                setStatusValue={setStatusValue}
                                setSpSelectValue={setSpSelectValue}
                                setPrioritySelectValue={setPrioritySelectValue}
                                useIssue={useIssue}
                            ></CardTableRow>
                        ))}
                        <CardTableHeader title="На проверке" cards={filteredCards.filter(card => card.status.id === 5)} />
                        {filteredCards.filter(card => card.status.id === 5).map((row) => (
                            <CardTableRow
                                card={row}
                                key={row.id}
                                editTask={editTask}
                                setRepositorySelectValue={setRepositorySelectValue}
                                setResponsibleSelectValue={setResponsibleSelectValue}
                                setAccompliceSelectValue={setAccompliceSelectValue}
                                setStatusValue={setStatusValue}
                                setSpSelectValue={setSpSelectValue}
                                setPrioritySelectValue={setPrioritySelectValue}
                                useIssue={useIssue}
                            ></CardTableRow>
                        ))}
                        <CardTableHeader title="На доработке" cards={filteredCards.filter(card => card.status.id === 6)} />
                        {filteredCards.filter(card => card.status.id === 6).map((row) => (
                            <CardTableRow
                                card={row}
                                key={row.id}
                                editTask={editTask}
                                setRepositorySelectValue={setRepositorySelectValue}
                                setResponsibleSelectValue={setResponsibleSelectValue}
                                setAccompliceSelectValue={setAccompliceSelectValue}
                                setStatusValue={setStatusValue}
                                setSpSelectValue={setSpSelectValue}
                                setPrioritySelectValue={setPrioritySelectValue}
                                useIssue={useIssue}
                            ></CardTableRow>
                        ))}
                        <CardTableHeader title="Завершенные" cards={filteredCards.filter(card => card.status.id === 7)} />
                        {filteredCards.filter(card => card.status.id === 7).map((row) => (
                            <CardTableRow
                                card={row}
                                key={row.id}
                                editTask={editTask}
                                setRepositorySelectValue={setRepositorySelectValue}
                                setResponsibleSelectValue={setResponsibleSelectValue}
                                setAccompliceSelectValue={setAccompliceSelectValue}
                                setStatusValue={setStatusValue}
                                setSpSelectValue={setSpSelectValue}
                                setPrioritySelectValue={setPrioritySelectValue}
                                useIssue={useIssue}
                            ></CardTableRow>
                        ))}
                        <CardTableHeader title="Запланированные" cards={filteredCards.filter(card => card.status.id === 1)} />
                        {filteredCards.filter(card => card.status.id === 1).map((row) => (
                            <CardTableRow
                                card={row}
                                key={row.id}
                                editTask={editTask}
                                setRepositorySelectValue={setRepositorySelectValue}
                                setResponsibleSelectValue={setResponsibleSelectValue}
                                setAccompliceSelectValue={setAccompliceSelectValue}
                                setStatusValue={setStatusValue}
                                setSpSelectValue={setSpSelectValue}
                                setPrioritySelectValue={setPrioritySelectValue}
                                useIssue={useIssue}
                            ></CardTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="sprint-container">
                <div className='sprint-container__sprints'>
                    <IconButton style={{ padding: 0 }} onClick={getPreviousSprint} disabled={sprintPage === sprintPagesCount - 1}>
                        <ArrowLeft sx={{ fontSize: 20 }} />
                    </IconButton>
                    <div className='sprint-container__sprints__data'>
                        {
                            isLoaded
                                ? filteredSprints.last_sprints.map(s =>
                                    <p
                                        key={s.id}
                                        className="sprint-container__sprints__item"
                                        id={`sprint-${s.month * s.year + s.month_number}`}
                                        onClick={(event) => { getSprintForId(event, s) }}
                                    >
                                        {`${s.month_number}-${getMonth(s.month, s.year)} (${getCountWeeksInSprint(s.date_start, s.date_end)})`}
                                    </p>
                                )
                                : null
                        }
                    </div>
                    <IconButton style={{ padding: 0 }} onClick={getNextSprint} disabled={sprintPage === 0}>
                        <ArrowRight sx={{ fontSize: 20 }} />
                    </IconButton>
                    {
                        searchValue !== ''
                            ? <p
                                key="all"
                                className="sprint-container__sprints__item"
                                id="all"
                                onClick={(event) => { findFromAllCards(event) }}
                                style={{ minWidth: 50, width: 50 }}
                            >
                                Все
                            </p>
                            : null
                    }
                </div>
                <div className='sprint-container__sprints'>
                    <IconButton style={{ padding: 0 }} onClick={getPreviousMonthSprint} disabled={monthSprintPage === monthSprintPagesCount - 1}>
                        <ArrowLeft sx={{ fontSize: 20 }} />
                    </IconButton>
                    <div className='sprint-container__sprints__data'>
                        {
                            isLoaded
                                ? filteredSprints.month_sprints.map(
                                    s =>
                                        <p
                                            key={s.month + s.year}
                                            className="sprint-container__sprints__item"
                                            id={`monthSprint-${s.month * s.year}`}
                                            onClick={(event) => { getSprintForMonth(event, s) }}
                                        >
                                            {formattedData(s.month, s.year)}
                                        </p>
                                )
                                : null
                        }
                    </div>
                    <IconButton style={{ padding: 0 }} onClick={getNextMonthSprint} disabled={monthSprintPage === 0}>
                        <ArrowRight sx={{ fontSize: 20 }} />
                    </IconButton>
                </div>
            </div>
            {showSheduleSprint && authUserData && authUserData.is_head_of_department
                ? <Button variant='contained' id="schedule-sprint" onClick={sheduleSprintButtonClick}>
                    Запланировать
                </Button>
                : null}
            {showSummarizeButton && authUserData && authUserData.is_head_of_department
                ? <Button variant='contained' id="schedule-sprint" onClick={summarizeButtonClick}>
                    Подвести итоги
                </Button>
                : null}
            {showEditForm ? <TaskEditForm showEditTask={setShowEditForm} task={editedTask}></TaskEditForm> : null}
        </div>
    );
}