import React, { useState } from 'react';
import {
    TextField,
    Button,
    FormLabel,
    InputAdornment,
    IconButton,
    Checkbox,
    FormControlLabel
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import '../styles/admin.scss';
import { getConfiguration, updateConfiguration } from '../components/api';

export function GeneralSettingsPage() {
    const [isLoaded, setIsLoaded] = useState(false);
    const [dbServer, setDbServer] = useState("");
    const [dbPort, setDbPort] = useState("");
    const [dbName, setDbName] = useState("");
    const [dbLogin, setDbLogin] = useState("");
    const [dbPassword, setDbPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [tag, setTag] = useState("");
    const [addToUpdateTag, setAddToUpdateTag] = useState("");
    const [gitUsername, setGitUsername] = useState("");
    const [gitToken, setGitToken] = useState("");
    const [showGitToken, setShowGitToken] = useState(false);
    const [repositoryBitrixField, setRepositoryBitrixField] = useState("");
    const [bitrixUrl, setBitrixUrl] = useState("");
    const [bitrixRequestToken, setBitrixRequestToken] = useState("");
    const [showBitrixRequestToken, setShowBitrixRequestToken] = useState(false);
    const [gitRequestToken, setGitRequestToken] = useState("");
    const [showGitRequestToken, setShowGitRequestToken] = useState(false);
    const [supportCategoryId, setSupportCategoryId] = useState("");
    const [rulesCategoryId, setRulesCategoryId] = useState("");
    const [issuer, setIssuer] = useState("");
    const [audience, setAudience] = useState("");
    const [authKey, setAuthKey] = useState("");
    const [showAuthKey, setShowAuthKey] = useState(false);
    const [useIssue, setUseIssue] = useState(false);

    React.useEffect(() => {
        getConfiguration().then(data => {
            setDbServer(data.dB_server);
            setDbPort(data.dB_port);
            setDbName(data.dB_name);
            setDbLogin(data.dB_login);
            setDbPassword(data.dB_password);
            setTag(data.tag);
            setAddToUpdateTag(data.addToUpdateTag);
            setGitUsername(data.gitUsername);
            setGitToken(data.gitToken);
            setRepositoryBitrixField(data.repositoryBitrixField);
            setBitrixUrl(data.bitrixUrl);
            setBitrixRequestToken(data.bitrixRequestToken);
            setGitRequestToken(data.gitRequestToken);
            setSupportCategoryId(data.supportCategoryId);
            setRulesCategoryId(data.rulesCategoryId);
            setIssuer(data.auth_options.issuer);
            setAudience(data.auth_options.audience);
            setAuthKey(data.auth_options.key);
            setUseIssue(data.useIssue);
            setIsLoaded(true);
        })
    }, [!isLoaded]);

    const updateConfigurationButtonClick = () => {
        updateConfiguration({
            DB_server: dbServer,
            DB_port: dbPort,
            DB_name: dbName,
            DB_login: dbLogin,
            DB_password: dbPassword,
            tag: tag,
            addToUpdateTag: addToUpdateTag,
            gitUsername: gitUsername,
            gitToken: gitToken,
            repositoryBitrixField: repositoryBitrixField,
            bitrixUrl: bitrixUrl,
            bitrixRequestToken: bitrixRequestToken,
            gitRequestToken: gitRequestToken,
            supportCategoryId: supportCategoryId,
            rulesCategoryId: rulesCategoryId,
            useIssue: useIssue,
            auth_options: {
                issuer: issuer,
                audience: audience,
                key: authKey
            }
        })
    }

    return (
        <div>
            <Button
                variant="contained"
                style={{ marginLeft: 10 }}
                onClick={() => updateConfigurationButtonClick()}
            >
                Cохранить
            </Button>
            <div className='settings'>
                <div className='settings__block'>
                    <FormLabel>База данных</FormLabel>
                    <TextField
                        label="Сервер БД"
                        className='settings__text-field'
                        variant="outlined"
                        value={dbServer}
                        onChange={(event) => setDbServer(event.target.value)}
                    />
                    <TextField
                        label="Порт БД"
                        className='settings__text-field'
                        variant="outlined"
                        value={dbPort}
                        onChange={(event) => setDbPort(event.target.value)}
                    />
                    <TextField
                        label="Имя БД"
                        className='settings__text-field'
                        variant="outlined"
                        value={dbName}
                        onChange={(event) => setDbName(event.target.value)}
                    />
                    <TextField
                        label="Имя пользователя БД"
                        className='settings__text-field'
                        variant="outlined"
                        value={dbLogin}
                        onChange={(event) => setDbLogin(event.target.value)}
                    />
                    <TextField
                        label="Пароль пользователя БД"
                        className='settings__text-field'
                        variant="outlined"
                        type={showPassword ? 'text' : 'password'}
                        value={dbPassword}
                        onChange={(event) => setDbPassword(event.target.value)}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                        }}
                    />
                </div>
                <div className='settings__block'>
                    <FormLabel>Bitrix</FormLabel>
                    <TextField
                        label="Тег"
                        className='settings__text-field'
                        variant="outlined"
                        value={tag}
                        onChange={(event) => setTag(event.target.value)}
                    />
                    <TextField
                        label="Тег - признак обновления"
                        className='settings__text-field'
                        variant="outlined"
                        value={addToUpdateTag}
                        onChange={(event) => setAddToUpdateTag(event.target.value)}
                    />
                    <TextField
                        label="Имя поля репозитория в Bitrix"
                        className='settings__text-field'
                        variant="outlined"
                        value={repositoryBitrixField}
                        onChange={(event) => setRepositoryBitrixField(event.target.value)}
                    />
                    <TextField
                        label="URL WebHook Bitrix"
                        className='settings__text-field'
                        variant="outlined"
                        value={bitrixUrl}
                        onChange={(event) => setBitrixUrl(event.target.value)}
                    />
                    <TextField
                        label="Токен запроса Bitrix"
                        className='settings__text-field'
                        variant="outlined"
                        type={showBitrixRequestToken ? 'text' : 'password'}
                        value={bitrixRequestToken}
                        onChange={(event) => setBitrixRequestToken(event.target.value)}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowBitrixRequestToken(!showBitrixRequestToken)}
                                        edge="end"
                                    >
                                        {showBitrixRequestToken ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                        }}
                    />
                    <TextField
                        type="number"
                        label="ID категории задач поддержки"
                        className='settings__text-field'
                        variant="outlined"
                        value={supportCategoryId}
                        onChange={(event) => setSupportCategoryId(event.target.value)}
                    />
                    <TextField
                        type="number"
                        label="ID категории задач на права"
                        className='settings__text-field'
                        variant="outlined"
                        value={rulesCategoryId}
                        onChange={(event) => setRulesCategoryId(event.target.value)}
                    />
                </div>
                <div className='settings__block'>
                    <FormLabel>GitHub</FormLabel>
                    <TextField
                        label="Имя пользователя GitHub"
                        className='settings__text-field'
                        variant="outlined"
                        value={gitUsername}
                        onChange={(event) => setGitUsername(event.target.value)}
                    />
                    <TextField
                        label="Токен пользователя GitHub"
                        className='settings__text-field'
                        variant="outlined"
                        type={showGitToken ? 'text' : 'password'}
                        value={gitToken}
                        onChange={(event) => setGitToken(event.target.value)}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowGitToken(!showGitToken)}
                                        edge="end"
                                    >
                                        {showGitToken ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                        }}
                    />
                    <TextField
                        label="Токен запроса GitHub"
                        className='settings__text-field'
                        variant="outlined"
                        type={showGitRequestToken ? 'text' : 'password'}
                        value={gitRequestToken}
                        onChange={(event) => setGitRequestToken(event.target.value)}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowGitRequestToken(!showGitRequestToken)}
                                        edge="end"
                                    >
                                        {showGitRequestToken ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                        }}
                    />

                    <TextField
                        className='settings__text-field'
                        variant="outlined"
                        InputProps={{
                            startAdornment:
                                <InputAdornment position="start">
                                    <FormControlLabel
                                        control={<Checkbox checked={useIssue} onChange={(event) => setUseIssue(event.target.checked)} />}
                                        label="Использовать Issue"
                                    />
                                </InputAdornment>
                        }}
                    />
                </div>
                <div className='settings__block'>
                    <FormLabel>Токен авторизации</FormLabel>
                    <TextField
                        label="Издатель"
                        className='settings__text-field'
                        variant="outlined"
                        value={issuer}
                        onChange={(event) => setIssuer(event.target.value)}
                    />
                    <TextField
                        label="Потребитель"
                        className='settings__text-field'
                        variant="outlined"
                        value={audience}
                        onChange={(event) => setAudience(event.target.value)}
                    />
                    <TextField
                        label="Ключ безопасности"
                        className='settings__text-field'
                        variant="outlined"
                        type={showAuthKey ? 'text' : 'password'}
                        value={authKey}
                        onChange={(event) => setAuthKey(event.target.value)}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowAuthKey(!showAuthKey)}
                                        edge="end"
                                    >
                                        {showAuthKey ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                        }}
                    />
                </div>

            </div>
        </div>
    );
}