import React, { useState } from 'react';
import { useStore } from 'effector-react';
import { $users, setUsers } from '../store/cards';
import { getUsers, deleteUser } from '../components/api';
import '../styles/users.scss';
import {
    Button,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@mui/material';
import { UserTableRow, UserEditForm } from '../components';

const default_user_value = {
    id: null,
    bitrix_user_id: '',
    git_user_login: '',
    name: '',
    surname: '',
    patronymic: '',
    is_head_of_department: false,
    domain_name: '',
    active: true,
    show_in_report: true,
}

export function UsersPage() {
    const users = useStore($users);
    const [showEditForm, setShowEditForm] = useState(false);
    const [editedUser, setEditedUser] = useState(null);
    const [showDialogDelete, setShowDialogDelete] = useState(false);

    React.useEffect(() => {
        getUsers().then(data => setUsers(data));
    }, [users.length]);

    const addUser = () => {
        setEditedUser(default_user_value);
        setShowEditForm(true);
    };

    const handleCloseDialog = (event, dialogResult) => {
        if (dialogResult) {
            deleteUser(editedUser.id).then(_ => getUsers().then(data => setUsers(data)));
        }
        setShowDialogDelete(false);
    };

    const closeEditForm = (editResult) => {
        setShowEditForm(false);
        if(editResult) getUsers().then(data => setUsers(data));
    }

    return (
        <div>
            <Button variant="contained" onClick={addUser}>Добавить</Button>
            <div style={{ width: 1000 }}>
                <TableContainer component={Paper} id="tasks-table">
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell width={300}><b>Имя</b></TableCell>
                                <TableCell><b>Доменное имя</b></TableCell>
                                <TableCell><b>Имя GitHub</b></TableCell>
                                <TableCell align="center"><b>Id Bitrix</b></TableCell>
                                <TableCell align="center"><b>Руководитель</b></TableCell>
                                <TableCell align="center"><b>Выводить в отчёт</b></TableCell>
                                <TableCell align="center"><b>Активен</b></TableCell>
                                <TableCell align="center"><b>Действия</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.map(user =>
                                <UserTableRow
                                    key={user.id}
                                    user={user}
                                    setShowEditForm={setShowEditForm}
                                    setEditedUser={setEditedUser}
                                    setShowDialogDelete={setShowDialogDelete}
                                />)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            {showEditForm ? <UserEditForm closeEditForm={closeEditForm} user={editedUser} /> : null}
            <Dialog
                open={showDialogDelete}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Вы уверенны, что хотите удалить пользователя?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(event) => handleCloseDialog(event, true)} color="primary">
                        Да
                    </Button>
                    <Button onClick={(event) => handleCloseDialog(event, false)} color="primary" autoFocus>
                        Нет
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
};