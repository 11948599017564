import React, { useState } from 'react';
import { useStore } from 'effector-react';
import { $achievements, setAchievements } from '../store/cards';
import { deleteAchievement, getAchievements } from '../components/api';
import '../styles/admin.scss';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
} from '@mui/material';
import { AchievementEditForm, AchievementTableRow } from '../components';

const default_achievemenent_value = {
    id: null,
    name: '',
    icon: '',
    color: '',
    condition: 0,
    active: true
}


export function AchievementsPage() {
    const achievements = useStore($achievements);
    const [showEditForm, setShowEditForm] = useState(false);
    const [editedAchievement, setEditedAchievement] = useState(null);
    const [showDialogDelete, setShowDialogDelete] = useState(false);

    React.useEffect(() => {
        getAchievements().then(data => setAchievements(data));
    }, [achievements.length])

    const addAchievement = () => {
        setEditedAchievement(default_achievemenent_value);
        setShowEditForm(true);
    };

    const handleCloseDialog = (event, dialogResult) => {
        if (dialogResult) {
            deleteAchievement(editedAchievement.id).then(_ => {
                closeCallback();
                setShowDialogDelete(false);
            });
        }
    };

    const closeCallback = () => {
        setTimeout(() => {
            getAchievements().then(data => setAchievements(data));
        }, 300);
    }

    return (
        <div>
            <Button variant="contained" id="add-button" onClick={addAchievement}>Добавить</Button>
            <div style={{ width: 1000 }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 730 }} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell><b>Наименование</b></TableCell>
                                <TableCell><b>Иконка</b></TableCell>
                                <TableCell><b>Цвет</b></TableCell>
                                <TableCell width={240}><b>Условие</b></TableCell>
                                <TableCell><b>Активность</b></TableCell>
                                <TableCell><b>Действия</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {achievements.map(achievement => {
                                return (
                                    <AchievementTableRow
                                        key={achievement.id}
                                        achievement={achievement}
                                        setShowEditForm={setShowEditForm}
                                        setEditedAchievement={setEditedAchievement}
                                        setShowDialogDelete={setShowDialogDelete}
                                    />
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            {showEditForm ? <AchievementEditForm setShowEditForm={setShowEditForm} achievement={editedAchievement} closeCallback={closeCallback}></AchievementEditForm> : null}
            <Dialog
                open={showDialogDelete}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Вы уверенны, что хотите удалить достижение?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(event) => handleCloseDialog(event, true)} color="primary">
                        Да
                    </Button>
                    <Button onClick={(event) => handleCloseDialog(event, false)} color="primary" autoFocus>
                        Нет
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}