import React, { useState } from 'react';
import { useStore } from 'effector-react';
import { $repositories, setRepositories } from '../store/cards';
import { deleteRepository, getRepositories } from '../components/api';
import '../styles/admin.scss';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    IconButton
} from '@mui/material';
import { RepositoryEditForm } from '../components';
import { Edit, Delete } from '@mui/icons-material';

const default_repository_value = {
    id: null,
    bitrix_code: 0,
    name: '',
    project_id: undefined
}

export function RepositoriesPage() {
    const repositories = useStore($repositories);
    const [showEditForm, setShowEditForm] = useState(false);
    const [editedRepository, setEditedRepository] = useState(null);
    const [showDialogDelete, setShowDialogDelete] = useState(false);

    React.useEffect(() => {
        getRepositories().then(data => setRepositories(data));
    }, [repositories.length])

    const addRepository = () => {
        setEditedRepository(default_repository_value);
        setShowEditForm(true);
    };

    const editRepository = (event, repository) => {
        setEditedRepository(repository);
        setShowEditForm(true);
    };

    const deleteRepositoryButtonClick = (event, repository) => {
        setEditedRepository(repository);
        setShowDialogDelete(true);
    };

    const handleCloseDialog = (event, dialogResult) => {
        if (dialogResult) {
            deleteRepository(editedRepository.id);
            closeCallback();
        }
        setShowDialogDelete(false);
    };

    const closeCallback = () => {
        setTimeout(() => {
            getRepositories().then(data => setRepositories(data));
        }, 300);
    }

    return (
        <div>
            <Button variant="contained" id="add-button" onClick={addRepository}>Добавить</Button>
            <div style={{ width: 1000 }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 610 }} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell><b>Наименование</b></TableCell>
                                <TableCell><b>Код битрикс</b></TableCell>
                                <TableCell><b>ID проекта</b></TableCell>
                                <TableCell><b>Цвет</b></TableCell>
                                <TableCell><b>Действия</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {repositories.map(repository => {
                                return (
                                    <TableRow key={repository.id} hover={true}>
                                        <TableCell width={200}>{repository.name}</TableCell>
                                        <TableCell width={150}>{repository.bitrix_code}</TableCell>
                                        <TableCell width={130}>{repository.project_id}</TableCell>
                                        <TableCell width={130}>
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                <div style={{ width: 10, height: 10, background: repository.color, marginRight: 5 }}></div>
                                                {repository.color}
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton onClick={(event) => editRepository(event, repository)} sx={{ padding: 0, marginLeft: 1, marginRight: 1 }}>
                                                <Edit sx={{ fontSize: 20 }} />
                                            </IconButton>
                                            <IconButton onClick={(event) => deleteRepositoryButtonClick(event, repository)} sx={{ padding: 0, marginLeft: 1, marginRight: 1 }}>
                                                <Delete sx={{ fontSize: 20 }} />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            {showEditForm ? <RepositoryEditForm setShowEditForm={setShowEditForm} repository={editedRepository} closeCallback={closeCallback}></RepositoryEditForm> : null}
            <Dialog
                open={showDialogDelete}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Вы уверенны, что хотите удалить спринт?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(event) => handleCloseDialog(event, true)} color="primary">
                        Да
                    </Button>
                    <Button onClick={(event) => handleCloseDialog(event, false)} color="primary" autoFocus>
                        Нет
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )

}